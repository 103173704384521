/* eslint-disable  */
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import CediSymbol from "../../Components/CediSymbol";
import Loader from "../../Components/Loader";
import { dataActionCreators } from "../../services/Actions";
import "../css/fundDed.css";
import "../css/pages.css";
import RenderAdminPage from "../RenderAdminPage";
import DataTable from 'react-data-table-component';
import { TextField } from "@material-ui/core";
import { GetTotal } from "../../services/Actions/dataAction";
import { GhanaMoneyDisplay } from "../../helpers/helper";

function LoanDeduction() {
  const dispatch = useDispatch();
  const { GetAllLoans } = bindActionCreators(
    dataActionCreators,
    dispatch
  );
  const [search, setSearch] = useState(null);
  const [limit, setLimit] = useState(1000);

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = limit;
  const [currentPage, setCurrentPage] = useState(0);

  const data = useSelector((state) => state.data);
  const [filteredData, setFilteredData] = useState([]);

  const { loans, paginate, isLoading, totalLoans, total_loans } = data;

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;

    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(loans?.slice(itemOffset, endOffset));
    setPageCount(Number(paginate?.total));
  }, [itemOffset, itemsPerPage, loans]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    console.log("event", event);

    GetAllLoans(true, event.selected + 1, limit);

    console.log("currentPage", currentPage);

    const newOffset = (event.selected * itemsPerPage) % paginate?.total;

    setItemOffset(newOffset);
  };

  console.log("data", data);

  const handleLimit = (e) => {
    setLimit(e.target.value);
  };

  const onSearchTextChange = debounce((e) => {
    if (e?.target?.value) {
      setSearch(e?.target?.value ?? null);
      console.log("search", search);
      console.log("e?.target?.value", e?.target?.value);
      GetAllLoans(
        true,
        1,
        15,
        `${e?.target?.value ? "&search=" + e?.target?.value : ""}`
      );
    } else {
      setSearch(null);
      GetAllLoans(true, 1, 15, null);
    }
  }, 800);

  // const handleSearch = debounce((e) => {
  //     setSearch(prevState => {
  //       return {
  //         ...prevState,
  //         [e.target.name] : e.target.value
  //       }
  //     })
  //     if(search){
  //       console.log('search', search)
  //        GetAllLoans(true, 1, 15, `&search=${search}`)
  //     }else{
  //       GetAllLoans(true, 1, 15, null)
  //     }
  // }, 800)

  // const handleSearch = (e) => {
  //   setSearch(e.target.value)
  // }

  useEffect(() => {
    GetAllLoans(true, 1, limit);
     dispatch(GetTotal())
  }, []);

  useEffect(() => {
    GetAllLoans(true, 1, limit, search ? "&search=" + search : "");
  }, [limit]);

  useEffect(() => {
    setFilteredData(data?.loans)
  }, [data?.loans]);

  

  // const handleFilterChange = (text) => {
  //   // const filteredRows = columns[0].customFilter(data, text);
  //   console.log('filterefilteredDatadRows', filteredData)
  //   const filteredRows = filteredData.filter(row => row.user.name.toLowerCase().includes(text.toLowerCase()));
  //   setFilteredData(filteredRows);
  //   console.log('filteredRows', filteredRows)
  // };

  const handleFilterChange = (event) => {
     const newData = filteredData.filter(row => {
      return row.user?.name?.toLowerCase().includes(event.target.value.toLowerCase())
     })
     setFilteredData(newData);
  }

const columns = [
	{
		name: ' Member Info',
		selector: row => row.user.name,
    sortable: true,
    cell: row => (      <div className="flex">
    <div className="flex-shrink-0 w-10 h-10">
      {row?.user?.profile_image ? (
        <img
          className="w-full h-full rounded-full"
          src={row?.user?.profile_image}
          alt=""
        />
      ) : (
        <>
          {row?.user?.name?.split(" ")[0] ===
          "Mr." ? (
            <img
              className="w-full h-full rounded-full"
              src="/images/dev/avarta2.jpeg"
              alt=""
            />
          ) : (
            <img
              className="w-full h-full rounded-full"
              src="/images/dev/woman-avarta.jpeg"
              alt=""
            />
          )}
        </>
      )}
    </div>
    <div className="ml-3">
      <p className="text-gray-900 whitespace-no-wrap">
        {row?.user?.name}
      </p>
      <p className="text-gray-600 whitespace-no-wrap">
        {row?.staff_id}
      </p>
    </div>
  </div>),
    // customFilter: (rows, text) => {
    //   return rows.filter(row => {
    //     // Perform filtering based on the text and the row's data
    //     return row.user.name.toLowerCase().includes(text.toLowerCase());
    //   });
    // }
	},
	// {
	// 	name: 'Name',
	// 	selector: row => row.user.name,
	// },
	{
		name: 'Amount',
		selector: row => row.deduction,
    sortable: true,
    cell: row => (
      <p className="text-gray-900 whitespace-no-wrap">
      <CediSymbol />
      {row?.deduction}
    </p>
    )
	},
	{
		name: 'Period',
		selector: row => row.period,
    sortable: true,
    cell: row => (
      <p className="text-gray-900 whitespace-no-wrap">
      {row?.period}
    </p>
    ),
    textFilter: true
	},
	{
		name: 'Transaction Type',
		selector: row => row.transaction_type,
    cell: row => (
      <p className="text-gray-900 whitespace-no-wrap">
      {row?.transaction_type}
    </p>
    )
	},
	{
		name: 'Facility',
		selector: row => row.user?.facility,
    cell: row => (
      <p className="text-gray-900 whitespace-no-wrap">
      {row?.user?.facility}
    </p>
    )
	},
];




  return (
    <div className="LoanDeduction">
      <RenderAdminPage>
      {isLoading && <Loader/>}
        <div className="row container pt-2">
          <div className="col-md-7">
            <label htmlFor="search">Search Query:</label>
            <input
              type={"search"}
              onChange={onSearchTextChange}
              id="search"
              placeholder="search by staff id, name"
              className="form-control"
            />
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-2">
            <label htmlFor="search">List to show:</label>
            <select className="form-control " onChange={handleLimit}>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={70}>70</option>
              <option value={100}>100</option>
              <option value={150}>150</option>
              <option value={250}>250</option>
              <option value={350}>350</option>
              <option value={500}>500</option>
              <option value={600}>600</option>
            </select>
          </div>
        </div>
         
        {/* <DataTable
			columns={columns}
			// data={data?.loans}
      data={filteredData }
      pagination
		/> */}

        <div className="container mx-auto px-4 sm:px-8">
          <div className="py-8">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold leading-tight">
                Loan Deductions
              </h2>
                <div>Total: <span className="font-bold">  <GhanaMoneyDisplay amount={ total_loans ?? totalLoans} /></span></div>
            </div>
            <div className="-mx-1 sm:-mx-8 px-4 sm:px-8 py-0 overflow-x-auto">
              <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-1 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Member Info
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Amount
                       
                      </th>
                      <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Period
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Transaction
                       
                      </th>
                      <th className="px-1 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Facility
                      </th>
                      <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.loans?.length > 0 ? (
                      <>
                        {data?.loans?.map((loan, index) => {
                          return (
                            <tr key={index +1}>
                              <td className="px-1 py-3 border-b border-gray-200 bg-white text-sm">
                                <div className="flex">
                                  <div className="flex-shrink-0 w-10 h-10">
                                    {loan?.user?.profile_image ? (
                                      <img
                                        className="w-full h-full rounded-full"
                                        src={loan?.user?.profile_image}
                                        alt=""
                                      />
                                    ) : (
                                      <>
                                        {loan?.user?.name?.split(" ")[0] ===
                                        "Mr." ? (
                                          <img
                                            className="w-full h-full rounded-full"
                                            src="/images/dev/avarta2.jpeg"
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            className="w-full h-full rounded-full"
                                            src="/images/dev/woman-avarta.jpeg"
                                            alt=""
                                          />
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div className="ml-3">
                                    <p className="text-gray-900 whitespace-no-wrap">
                                      {loan?.user?.name}
                                    </p>
                                    <p className="text-gray-600 whitespace-no-wrap">
                                      {loan?.staff_id}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td className="px-5 py-1 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  <CediSymbol />
                                  {loan?.deduction}
                                </p>
                               
                              </td>
                              <td className="px-2 py-1 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  {loan?.period}
                                </p>
                               
                              </td>
                              <td className="px-5 py-1 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  {loan?.transaction_type}
                                </p>
                              </td>
                              <td className="px-1 py-1 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  {loan?.user?.facility}
                                </p>
                              </td>
                              <td className="px-2 py-1 border-b border-gray-200 bg-white text-sm text-right">
                                <button
                                  type="button"
                                  className="inline-block text-gray-500 hover:text-gray-700"
                                >
                                  <svg
                                    className="inline-block h-6 w-6 fill-current"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M12 6a2 2 0 110-4 2 2 0 010 4zm0 8a2 2 0 110-4 2 2 0 010 4zm-2 6a2 2 0 104 0 2 2 0 00-4 0z" />
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <>Data is not available</>
                    )}
                  </tbody>
                </table>
              </div>

              {loans.length > 0 && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-num"
                  previousLinkClassName="page-num"
                  nextLinkClassName="page-num"
                  activeLinkClassName="active"
                />
              )}
            </div>
          </div>
        </div>

      </RenderAdminPage>
    </div>
  );
}

export default LoanDeduction;
