import { dataConstants } from "../Constants/dataConstant";

const initialState = {
  isLoading: false,
  appliedLoans: [],
  submittedLoans: [],
  funds: [],
  total_fund: 0,
  total_loans: 0,
  fund_increment: [],
  members: [],
  member: {},
  loans: [],
  loan_detailed: {},
  dues: [],
  system_users: [],
  totalMembers: 0,
  totalActiveMembers: 0,
  totalDues: 0,
  totalFunds: 0,
  totalLoans: 0,
  totalMonthlyLoansBooked: 0,

  paginate: {
    total: 0,
    totalItems: 0,
    page: 1,
    size: 15,
    previousPage: null,
    nextPage: null,
    previousNumber: null,
    nextNumber: null,
    currentPageSize: 0,
  },
  totalLoanStats: {
    totalLoans: null,
    totalSubmited: null,
    totalAuthorized: null,
    totalInitiated: null,
    totalBooked: null,
    totalRejected: null,
    totalPaid: null,
  },
  loansStatistics: null,
  response: {
    state: null,
    message: "",
    action: "",
  },
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case dataConstants.LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case dataConstants.GET_TOTAL:
      return {
        ...state,
        totalMembers: action.payload.totalMembers,
        totalFunds: action.payload.totalFunds,
        totalDues: action.payload.totalDues,
        totalLoans: action.payload.totalLoans,
      };

    case dataConstants.GET_TOTAL_LOANS_BOOKED_MONTHLY:
      return {
        ...state,
        totalMonthlyLoansBooked: action.payload,
      };
    case dataConstants.GET_TOTAL_ACTIVE_NURSE:
      return {
        ...state,
        totalActiveMembers: action.payload.totalActiveMembers,
        
      };
    case dataConstants.GET_LOANS_STATISTICS:
      return {
        ...state,
       loansStatistics: action.payload.loansStatistics
      };
      
    case dataConstants.GET_TOTAL_LOANS_STATS:
      return {
        ...state,
        totalLoanStats: {
          totalLoans: action.payload.totalLoans,
          totalSubmited: action.payload.totalSubmited,
          totalInitiated: action.payload.totalInitiated,
          totalAuthorized: action.payload.totalAuthorized,
          totalBooked: action.payload.totalBooked,
          totalRejected: action.payload.totalRejected,
          totalPaid: action.payload.totalPaid,
        },
      };
   
      case dataConstants.GET_APPLIED_LOANS:
      return {
        ...state,
        appliedLoans: action.payload.appliedLoans,
        paginate: action.payload.paginate,
      };
    case dataConstants.GET_SUBMITTED_LOANS:
      return {
        ...state,
        submittedLoans: action.payload.submittedLoans,
        paginate: action.payload.paginate,
      };
    case dataConstants.GET_LOAN_DETAILED:
      return {
        ...state,
        loan_detailed: action.payload.loan_detailed,
      };
    case dataConstants.GET_FUNDS:
      return {
        ...state,
        funds: action.payload.funds,
        // totalFunds: action.payload.totalFunds,
        total_fund: action.payload.total_fund,
        paginate: action.payload.paginate,
      };
 
    case dataConstants.GET_FUND_INCREMENT_BY_ADMIN:
      return {
        ...state,
        fund_increment: action.payload.fund_increment,
      }
    case dataConstants.GET_MEMBERS:
      return {
        ...state,
        members: action.payload.members,
        paginate: action.payload.paginate,
      };
    case dataConstants.GET_A_MEMBER:
      return {
        ...state,
        member: action.payload.member,
      };
    case dataConstants.GET_DUES:
      return {
        ...state,
        dues: action.payload.dues,
        paginate: action.payload.paginate,
      };
    case dataConstants.GET_SYSTEM_USERS:
      return {
        ...state,
        system_users: action.payload.system_users,
      };
    case dataConstants.GET_LOANS:
      return {
        ...state,
        loans: action.payload.loans,
        total_loans: action.payload.total_loans,
        paginate: action.payload.paginate,
      };
    case dataConstants.RESPONSE:
      return {
        ...state,
        response: {
          state: action.response.state,
          message: action.response.message,
        },
      };

    default:
      return state;
  }
};

export default dataReducer;
