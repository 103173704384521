export const ROLES = {
  ADMIN: "ADMIN",
  LOAN_OFFICER: "LOAN_OFFICER",
  MART_OFFICER: "MART_OFFICER",
  INVESTMENT_OFFICER: "INVESTMENT_OFFICER",
  USER: "USER",
};


export const GhanaMoneyDisplay = ({ amount }) => {
  // Format the amount as currency
  const formattedAmount = new Intl.NumberFormat("en-GH", {
    style: "currency",
    currency: "GHS", // Ghanaian Cedi
  }).format(amount);

  return <span>{formattedAmount}</span>;
};